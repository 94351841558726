<script>
  import Picture from "./Picture.svelte";
  export let contents;
  const hasAPI = "IntersectionObserver" in window;
</script>

<style>
  .content-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 5px;
    align-items: center;
  }
</style>

<div class="content-grid">
  {#each contents as content, i}
    <Picture source={content} lazy={hasAPI && i > 1} />
  {/each}
</div>
