<script>
  import { onMount } from "svelte";
  import Grid from "./components/PictureGrid.svelte";

  export let contents = [];

  onMount(async () => {
    const res = await fetch(
      "https://jsonplaceholder.typicode.com/photos?_limit=100"
    );
    contents = await res.json();
  });
</script>

<style>
  :global(html, body) {
    padding: 0;
    margin: 0;
  }

  main {
    padding: 20px 8px;
  }

  footer {
    background-color: darkslategrey;
    color: #fff;
    padding: 20px 8px;
    margin: 0;
  }

  a {
    color: #fff;
  }
</style>

<main>
  <Grid {contents} />
</main>
<footer>
  Build with
  <a href="https://svelte.dev/">Svelte.js</a>
</footer>
