<script>
  export let source;
  export let lazy = false;

  let src = source;
  let observer = null;

  if (lazy) {
    src = "";
    observer = new IntersectionObserver(onIntersect, { rootMargin: "200px" });
  }

  function onIntersect(entries) {
    if (!src && entries[0].isIntersecting) {
      src = source;
    }
  }

  function lazyLoad(node) {
    observer && observer.observe(node);
    return {
      destroy() {
        observer && observer.unobserve(node);
      }
    };
  }
</script>

<div class="picture" use:lazyLoad>
  <img src={source.thumbnailUrl} alt={source.title} />
</div>
